<template>
  <div class="whole">
    <Nav v-if="navFlag == 1"></Nav>
    <videoNav v-else></videoNav>
    <div class="registerAll">
      <div class="bindRole" v-if="pageStep == 1">
        <div class="step-role">1</div>
        <div class="step-rlink"></div>
        <div class="role-con">
          <div class="role-text">请选择你要注册的角色</div>
          <div class="role">
            <div class="role-type">
              <el-radio-group v-model="userType">
                <el-radio :label="1">上传用户</el-radio>
                <el-radio :label="2">下载用户</el-radio>
              </el-radio-group>
            </div>
            <div class="role-down" v-if="userType == '1'">
              <el-radio-group v-model="daili" class="dailipower" @change="onChangeExclusive">
                <el-radio :label="3">独家代理</el-radio>
                <el-radio :label="4">一般代理</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="link">
            <el-button @click="onGoNext">下一步</el-button>
          </div>
        </div>
      </div>
      <div class="bindMobile" v-if="pageStep == 2">
        <!-- <div class="bindMobile"> -->
        <div class="step-one">2</div>
        <div class="step-link"></div>
        <div class="mobile-con">
          <div class="mobile">
            <span>手机号：</span>
            <el-input v-model="mobileInput" placeholder="请输入手机号" clearable @blur="onsetMobile"></el-input>
          </div>
          <div class="yanzhengCode">
            <span>图形验证码：</span>
            <el-input v-model="imgCode" placeholder="请输入图形验证码" clearable></el-input>
            <img :src="codeImg" alt="" class="yan-img" @click="changeCode" />
          </div>
          <div class="mobileback">
            <span>手机验证码：</span>
            <el-input v-model="smsCode" placeholder="请输入验证码" clearable></el-input>
            <el-button @click="onCaptcha" v-if="requestSMS">获取验证码</el-button>
            <el-button v-else>{{ requestSMSTimeout }}秒后重发</el-button>
          </div>
          <div class="step">
            <el-button @click="onNext">下一步</el-button>
          </div>
        </div>
      </div>
      <div class="bindName" v-if="pageStep == 3">
        <!-- <div class="bindName"> -->
        <div class="step-two">3</div>
        <div class="step-tlink"></div>
        <div class="name-con">
          <div class="tip">(*为必填项)</div>
          <div class="cname">
            <span>登录名(必须是字母或数字及其组合4位以上*)：</span>
            <el-input v-model="loginName" v-bind:class="{ redBorder: warnings.loginName }" placeholder="请输入内容" @blur="onlogoName" clearable></el-input>
            <span style="color: red" v-if="warnings.loginName">(登录名不正确)</span>
          </div>
          <div class="realName">
            <span>真实姓名(*)：</span>
            <el-input v-model="chnName" placeholder="请输入内容" clearable></el-input>
          </div>
          <div class="Idcard">
            <span>身份证号(18位数组成*)：</span>
            <span class="red">{{ extra.idNo == 'undefined' || extra.idNo == '' ? '未填写' : extra.idNo }}</span>
            <!-- <el-input v-model="IdCard" v-bind:class="{ redBorder: idcardShow }" placeholder="请输入内容" @blur="onSendIdcard" clearable></el-input> -->
            <!-- <span style="color: red" v-if="idcardShow">(身份证格式不正确)</span> -->
          </div>
          <div class="Idcardfront">
            <span>身份证正面(*)：</span>
            <div class="border">
              <el-upload
                ref="upload"
                class="avatar-uploader"
                accept=".jpg, .jpeg, .JPG, .JPEG"
                :action="idFrontImage"
                :with-credentials="true"
                :show-file-list="false"
                :on-success="onSuccessFront"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="idCardUploadURL" :style="{ backgroundImage: `url(` + idCardUploadURL + `)` }" class="avatar"></div>
                <div v-else class="el-icon-plus avatar-uploader-icon"></div>
              </el-upload>
            </div>
            <div class="cutFront" @click="onCutIdCard('front')">剪切身份证正面</div>
          </div>
          <div class="Idcardback">
            <span>身份证反面(*)：</span>
            <div class="border">
              <el-upload
                class="avatar-uploader"
                accept=".jpg, .jpeg, .JPG, .JPEG"
                :action="headImage"
                :show-file-list="false"
                :with-credentials="true"
                :on-success="onSuccessBack"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="idImgURLBack" :style="{ backgroundImage: `url(` + idImgURLBack + `)` }" class="avatar"></div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="cutBack" @click="onCutIdCard('back')">剪切身份证反面</div>
          </div>
          <div class="address">
            <span>通讯地址(*)：</span>
            <el-input v-model="extra.address" placeholder="请输入内容" clearable></el-input>
          </div>
          <div class="postCode">
            <span>邮编(6位数组成*)：</span>
            <el-input v-bind:class="{ redBorder: warnings.postCode }" v-model="extra.postCode" placeholder="请输入编码" @blur="onpostCode" clearable></el-input>
            <span style="color: red" v-if="warnings.postCode">(邮编格式不正确)</span>
          </div>
          <div class="email">
            <span>邮箱(*)：</span>
            <el-input v-model="extra.email" v-bind:class="{ redBorder: warnings.email }" placeholder="请输入邮箱" @blur="onSendEmail" clearable></el-input>
            <span style="color: red" v-if="warnings.email">(邮箱格式不合法)</span>
          </div>
          <div class="qq" v-if="userType == '1'">
            <span>常用QQ(*)：</span>
            <el-input v-model="extra.qq" v-bind:class="{ redBorder: warnings.qq }" placeholder="请输入qq" @blur="onSendQQ" clearable></el-input>
            <span style="color: red" v-if="warnings.qq">(qq格式不正确)</span>
          </div>
          <div class="weixin" v-if="userType == '1'">
            <span>常用微信(字母开头*)：</span>
            <el-input v-model="extra.wx" v-bind:class="{ redBorder: warnings.wx }" placeholder="请输入微信号" @blur="onweixin" clearable></el-input>
            <span style="color: red" v-if="warnings.wx">(微信格式不正确)</span>
          </div>
          <div class="telephone">
            <div class="tel-name">固定电话(主号码6位以上)：</div>
            <div class="area">
              <div class="area-name">区号:</div>
              <el-input v-model="telephone.areaCode" @blur="onareaCode" clearable></el-input>
            </div>
            <div class="tel-main">
              <div class="main-name">主号码:</div>
              <el-input v-bind:class="{ redBorder: warnings.maintelephone }" v-model="telephone.maintelephone" @blur="ontelephone" clearable placeholder="请输入主号码"></el-input>
            </div>
            <div class="tel-fen">
              <div class="fen-name">分机号:</div>
              <el-input v-model="telephone.fentelephone" clearable></el-input>
            </div>
          </div>
          <div class="company">
            <span>工作单位(*)：</span>
            <el-input v-model="extra.company" placeholder="请输入工作单位" clearable></el-input>
          </div>
          <div class="zhiwu">
            <span>职务(*)：</span>
            <el-input v-model="extra.head" placeholder="请输入职务" clearable></el-input>
          </div>
          <div class="bankaccont" v-if="userType == 1">
            <span>银行账户(16或19位等*)：</span>
            <el-input v-model="extra.bankAcc" v-bind:class="{ redBorder: warnings.bankAcc }" placeholder="请输入银行账户" clearable></el-input>
            <span style="color: red" v-if="warnings.bankAcc">(银行账户格式不正确)</span>
          </div>
          <div class="bank" v-if="userType == 1">
            <span>开户银行(与上述银行卡对应开户行*)：</span>
            <el-input v-model="extra.bank" placeholder="请输入开户行" clearable></el-input>
          </div>
          <div class="comment" v-if="userType == 1">
            <span>个人简介(100至150字*):</span>
            <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="extra.comment" @input="handleCommentChange" @blur="handlefinallychange"> </el-input>
            <i class="currenrsize">(当前字数：{{ commmentLength }})</i>
          </div>
          <div class="vip" v-if="userType == '1'">
            <span>何级摄影家协会会员(选填)：</span>
            <el-select v-model="extra.sheying" placeholder="请选择">
              <el-option v-for="item in sheyingList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="xinwen" v-if="userType == '1'">
            <span>何级新闻摄影学会会员(选填)：</span>
            <el-select v-model="extra.xinwen" placeholder="请选择">
              <el-option v-for="item in xinwenList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="sheji" v-if="userType == '1'">
            <span>何级设计师协会会员(选填)：</span>
            <el-select v-model="extra.sheji" placeholder="请选择">
              <el-option v-for="item in shejiList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="reward" v-if="userType == '1'">
            <span>获得过最高级别的专业奖(选填)：</span>
            <el-select v-model="extra.topReward" placeholder="请选择">
              <el-option v-for="item in rewardList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="representative" v-if="userType == 1">
            <div class="representative-one">
              <div class="representative-left">代表作品1(*):</div>
              <div class="representative-right">
                <div class="uploadTop">
                  <el-upload
                    ref="upload"
                    class="avatar-uploader"
                    accept=".jpg, .jpeg, .JPG, .JPEG"
                    :action="headImage"
                    :show-file-list="false"
                    :with-credentials="true"
                    :on-success="onSuccessRepresentative"
                    :before-upload="beforeAvatarUpload"
                    >上传代表作</el-upload
                  >
                </div>
                <div class="topPics">
                  <div class="top-con" v-for="(k, topIndex) in extra.topProducesList" :key="k + 'top_' + topIndex">
                    <div class="top-del el-icon-close" @click="ondelTopPics(topIndex)"></div>
                    <div class="top-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }"></div>
                    <div class="introl">
                      <span>作品介绍：</span>
                      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="k.text"> </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="photo" v-if="userType == '1'">
            <div class="text">上传用户头像(*)：</div>
            <div class="border">
              <el-upload
                class="avatar-uploader"
                accept=".jpg, .jpeg, .JPG, .JPEG"
                :action="headImage"
                :show-file-list="false"
                :with-credentials="true"
                :on-success="onGetheadImage"
                :before-upload="beforeAvatarUpload"
              >
                <div class="showimg" v-if="headImageUploadURL" :style="{ backgroundImage: `url(` + headImageUploadURL + `)` }"></div>
                <div v-else class="el-icon-plus avatar-uploader-icon"></div>
              </el-upload>
            </div>
            <div class="cutPic" @click="onCutHead">剪切头像</div>
          </div>
          <div class="pw">
            <span>输入密码(密码应至少6位，包含大写字母至少2个，小写字母至少2个，必须有数字，且必须有特殊符号*)：</span>
            <el-input v-model="loginPassword" placeholder="请输入内容" show-password @blur="onpassword" clearable></el-input>
          </div>
          <div class="resetpw">
            <span>确认密码(与上述密码一致*)：</span>
            <el-input v-model="repw" placeholder="请输入内容" show-password clearable></el-input>
          </div>
          <div class="btnsure">
            <el-button @click="onEnsure" type="success">完成注册</el-button>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
    <div class="xieyi-show" v-if="xieyiShow">
      <div class="close el-icon-circle-close" @click="onClose"></div>
      <div class="zhuce">
        <div class="zhuce-con" @scroll="gotoScroll">
          <div class="zhuce-title" v-if="userType == '1'">注册协议</div>
          <div class="zhuce-title" v-else>购买协议</div>
          <p>【审慎阅读】您在申请注册流程中点击同意前，应当认真阅读以下协议。请您务必审慎阅读、充分理解协议中相关条款内容。</p>
          <div class="xie" v-if="userType == '1'">
            <div class="shangchuan">
              <div class="title">视觉江苏网视觉版权作品委托代理协议</div>
              <!-- <div class="xieyi"></div> -->
              <div class="one">
                <div class="one-title">一、重要须知</div>
                <div class="one-content">
                  <p>
                    视觉江苏网(www.vijs.
                    Net，下同)是中共江苏省委机关报新华日报社主办、新华日报社全媒体视觉中心承办的视觉版权产品发布及销售平台，视觉江苏网除负责新华报业传媒集团视觉版权产品的采集、加工、存储外，同时向集团内部以及省内外媒体(包括但不限于报纸、杂志、出版社、网站、微媒、APP等)提供图片、音视频和视觉创意设计素材产品。
                  </p>
                  <p>本协议是视觉版权作品作者与视觉江苏网之间就版权作品作者自愿将作品在全球范围内委托视觉江苏网代理对客户的销售、许可使用而达成的服务条款。</p>
                  <p>本协议所称视觉版权作品包括但不限于图片、视频、创意素材。</p>
                  <P>本协议所称视觉版权作品作者包括但不限于上传作品的摄影记者、摄影师、摄影家、摄影爱好者、视频师和创意素材设计师。</P>
                  <P>用户完成注册程序并点击“同意”"按钮，即表示用户认可本协议所有的服务条款并与视觉江苏网达成协议!</P>
                  <P
                    >视觉江苏网如对本协议修订和补充，会在视觉版权作品作者进入下一步使用前的页面提示修改内容。如果视觉版权作品作者同意改动，则再一次激活“同意”按钮。如果视觉版权作品作者不接受，可第一时间解除本协议。解除协议前所上传作品仍按照本协议执行。</P
                  >
                </div>
              </div>
              <div class="two">
                <div class="two-title">二、保证义务</div>
                <div class="two-content">
                  <p>1、视觉江苏网的保证义务：</p>
                  <p>
                    视觉江苏网版权销售方为江苏新华日报传媒有限公司，有签订本协议并履行协议义务的资格与权利，代理活动不违反法律、法规，不侵犯他人的合法权益，不违反社会公德及公序良俗；
                  </p>
                  <p>视觉江苏网版权维权机构为新华报业传媒集团版权中心和新华报业传媒集团公司律师部，可以视觉江苏网的名义对外采取包括但不限于发函、诉讼等措施制止侵权行为；</p>
                  <p>
                    协议期间，视觉江苏网将尽力推广视觉版权作品作者的授权视觉版权作品，使视觉版权作品得到使用同时进行合理定价及收费，并按协议约定支付视觉版权作品作者的作品权利许可所得。
                  </p>
                  <p>2、视觉版权作品作者的保证义务</p>
                  <p>
                    保证在注册申请时提供的个人资料准确、真实、合法，如有变动及时更新，若由于视觉版权作品作者提供的个人资料不准确、不真实、不合法造成的损失完全由视觉版权作品作者个人承担；
                  </p>
                  <p>
                    保证授权的视觉版权作品不违背中华人民共和国的相关法律、法规，不涉及任何侵权事宜，不对任何民事主体造成诽谤、侵害隐私权，不触犯任何第三方的版权、商标权、肖像权;
                  </p>
                  <p>保证对自己所申请的帐号和密码安全负完全的责任，若发现任何非法使用自己帐户或其他安全问题的，应立即通知视觉江苏网；</p>
                  <p>
                    保证授权的视觉版权作品及相关文字信息为其专有著作权，如果授权视觉版权作品为与他人共享著作权的，必须明确提示，并保证共享著作权人不再向甲方提出任何权利主张;乙方因著作权不合法引起的所有法律责任与甲方无关。
                  </p>
                  <p>
                    保证在视觉版权作品著作权出现争议或发生侵权事件时，根据视觉江苏网的要求，及时、准确的提供任何文件、资料、信息以及视觉江苏网要求的其他协助，以帮助处理有关争议或侵权事件；视觉江苏网因使用视觉版权作品而遭遇侵权赔偿，可依据此协议向视觉版权作品作者予以全额追偿（包括但不限于因侵权所产生的赔偿、律师费用、公证费用及相关的一切合理开支）。
                  </p>
                </div>
              </div>
              <div class="three">
                <div class="three-title">三、许可范围</div>
                <div class="three-content">
                  <p>视觉版权作品作者对其所上传的视觉版权作品：</p>
                  <p>
                    1、许可视觉江苏网进行复制、翻译、修改、改编、汇编，且通过互联网和移动通讯网络在全球范围内的信息网络传播权。此外，委托视觉江苏网作为其作品在全球范围内的代理商，视觉江苏网有权将视觉版权作品作者委托代理之作品许可第三方使用，包括但不仅限于视觉江苏网有权将作品使用于国内外之出版物、广告宣传品及其他用途上；或由视觉江苏网销售或许可给任何第三方。
                  </p>
                  <p>
                    2、许可授予视觉江苏网筛选和编辑权，视觉江苏网有权对作品进行筛选、组合，并选择其认为适合的视觉版权作品进行编辑，并将其录入图片库、视频库或创意素材库;视觉江苏网有权对作品的尺寸、构图、文字或结构进行调整，并在作品上增加视觉江苏网的水印（含隐形水印）或其他不破坏作品主题的内容;
                  </p>
                  <p>
                    3、许可授予视觉江苏网作品定价权，视觉江苏网有权对作品按照市场需求进行定价并进行调整；视觉江苏网有权代理作者接受国内外下载用户支付稿酬，并按本协议规定之比例与视觉版权作品作者分享；
                  </p>
                  <p>
                    4、许可新华报业传媒集团所属子媒体使用权，新华报业传媒集团所属的报刊、网络、微博、微信、移动客户端及公众号有权使用视觉版权作品作者所上传视觉版权作品，并按照通常标准支付稿费。
                  </p>
                  <p>5、许可新华报业传媒集团所属各类子媒体在自己主办或与其他单位合办的展览、展示及出版等活动中使用；</p>
                  <p>6、许可新华报业传媒集团所属各类子媒体在自己主办或与其他单位合并的作品征集比赛中自动列为参赛作品；</p>
                  <p>7、许可授权视觉江苏网在政治宣传、社会公益活动时无偿使用的权利;同意在网页展示、客户使用时不一定有署名。</p>
                  <p>
                    8、独家代理作品被侵权时，许可视觉江苏网及其版权维护机构以自己的名义对外采取包括但不限于发函、诉讼等措施制止侵权行为，在此情况下，视觉版权作品作者应视觉江苏网的要求，应提供一切必要的帮助，包括但不限于提供著作权属证明、已对外授权名单等。
                  </p>
                </div>
                <div class="four">
                  <div class="four-title">四、收益权利</div>
                  <div class="four-content">
                    <p>视觉版权作品作者授权的作品：</p>
                    <p>
                      1、被新华报业传媒集团所属的网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体使用，由相关媒体按各自标准直接向视觉版权作品作者支付稿费，也可由相关媒体委托视觉江苏网代发稿费。视觉江苏网不收取中间费用。
                    </p>
                    <p>
                      2、被商业用户购买或者被非新华报业传媒集团所属网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体采用，视觉江苏网将向视觉版权作品作者支付不低于实际收到授权使用作品净收入的30%，视觉江苏网也可根据作品质量和视觉版权作品作者水平适当提高标准；视觉版权作品作者所得款项应按有关规定由视觉江苏网代缴个人收入所得税；
                    </p>
                    <p>3、视觉江苏网有权通过评选十佳、优秀摄影（视频、素材）师、发放特殊稿费等方式对视觉版权作品作者进行鼓励。</p>
                    <p>4、被新华报业传媒集团所属各类子媒体（含视觉江苏网）主办或与其他单位合办的展览、展示及出版等活动中使用，按照展览、展示或出版物规定标准支付稿费。</p>
                    <p>5、在新华报业传媒集团所属各类子媒体（含视觉江苏网）主办或与其他单位合并的作品征集比赛中自动列为参赛作品并获奖的，根据征稿启事标准获得全额奖金；</p>
                    <p>6、被视觉江苏网进行再创作的，新生作品的著作权属双方共有，权利许可之所有30%归视觉版权作品作者所有；</p>
                    <p>7、被客户使用作品所应收到或产生的收入被取消或视觉江苏网必须退款，此协议明确规定视觉江苏网有权对多付款项或未收款项在随后应付该摄影师的费用中扣除；</p>
                    <p>
                      8、独家代理之作品，视觉江苏网对第三方侵害著作权行为进行追究及法律诉讼，视觉江苏网负责预支法律开支（诉讼费、律师费等），追缴所得部分扣除法律开支后50%归视觉版权作品作者所有。
                    </p>
                  </div>
                </div>
                <div class="five">
                  <div class="five-title">五、收益支付</div>
                  <div class="five-content">
                    <p>1、被新华报业传媒集团所属的网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体使用的，由各媒体根据自身财务制度发放稿费。</p>
                    <p>
                      2、被商业用户购买或者被非新华报业传媒集团所属网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体采用的，在费用到达江苏新华日报传媒有限公司财务账号3个月内支付；
                    </p>
                    <p>
                      3、被视觉江苏网主办或与其他单位合办的展览、展示及出版等活动中使用，或在视觉江苏网主办或与其他单位合并的作品征集比赛中自动列为参赛作品并获奖的，在相关经费到江苏新华日报传媒有限公司财务账号3个月内支付；
                    </p>
                    <p>
                      4、收益均通过视觉版权作品作者注册时所登记个人银行账号支付，如该账号发生变动，视觉版权作品作者应该及时登录个人账号修改信息并通知视觉江苏网。未及时通知导致稿费无法发放的，由视觉版权作品作者本人承担。
                    </p>
                  </div>
                </div>
                <div class="six">
                  <div class="six-title">六、保密义务</div>
                  <div class="six-content">
                    <p>视觉版权作品作者须保管好自己的用户名和密码，如因视觉版权作品作者自己的原因造成用户名和密码泄漏，对因此造成的相关损失应由视觉版权作品作者自己负责。</p>
                    <p>
                      视觉版权作品作者如果发现自己的用户名和密码泄漏或者发现任何非法使用其用户名和密码的情况，有义务及时通知视觉江苏网，视觉江苏网在接到通知后应当及时采取补救措施。
                    </p>
                  </div>
                </div>
                <div class="seven">
                  <div class="seven-title">七、网络储存期限</div>
                  <div class="seven-content">
                    <p>视觉版权作品作者的作品数据将长期储存于视觉江苏网的网络服务器，视觉版权作品作者可以通过网络浏览、下载自己的作品。</p>
                    <p>视觉版权作品作者作品数据的网络储存期限由视觉江苏网决定。作品经视觉江苏网判断已经不具备使用价值的，视觉江苏网将在网络服务器上进行删除，无需告知作者。</p>
                  </div>
                </div>
                <div class="eight">
                  <div class="eight-title">八、其他事项</div>
                  <div class="eight-content">
                    <p>
                      1、本协议自签署之日起生效，有效期5年。除非双方提前终止本协议，期满之后，若双方没有异议，本协议将自然延续5年;如果一方要终止协议，须以书面形式正式通知另一方。在另一方收到正式书面通知之前，视觉版权作品代理协议仍然有效。在本协议期限届满后，用户在本协议有效期间提供的图片视觉江苏网仍有权存储、销售、推广，并按本协议约定的条件向用户支付稿酬。
                    </p>
                    <p>2、视觉版权作品作者如无书面文件表示异议，本协议签订之前视觉版权作品作者上传到视觉江苏网的作品，自动适用本协议各项条款。</p>
                    <p>3、本协议的双方当事人如因执行本协议发生争执，均可协商解决，如协商无法解决，任何一方有权向南京市有管辖权的人民法院提起诉讼。</p>
                    <p>
                      4、本协议的一条或几条条款被认定为无效、非法或不能执行，并不影响其他条款的效力和合法性，并且双方应当以最接近于该条款意图的新条款替代被认定为无效、非法和不能执行的条款。
                    </p>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="xie" v-else>
            <div class="buy">
              <div class="title">视觉江苏网视觉版权作品购买协议</div>
              <!-- <div class="xieyi"></div> -->
              <div class="one">
                <div class="one-title">一、重要须知</div>
                <div class="one-content">
                  <p>
                    视觉江苏网（网址：http://www.vijs.net）是由视觉江苏网关联公司拥有域名、并维护和合法经营、使用的国际互联网网站，视觉版权作品使用方根据本协议，成为经视觉江苏网许可而使用上述网站图片的使用人。
                  </p>
                  <p>
                    本协议是视觉版权作品使用方与视觉江苏网之间就版权作品在全球范围内的许可使用而达成的销售条款。本协议包含了视觉江苏网提供特定用途的图片，许可视觉版权作品使用方以非独占使用为特征的完整协议条款。
                  </p>
                  <p>本协议所称视觉版权作品包括但不限于图片、视频、创意素材。</p>
                  <P>本协议所称视觉版权作品使用方包括但不限于个人和单位。</P>
                  <P>用户完成注册程序并点击"同意"按钮，即表示用户认可本协议所有的服务条款并与视觉江苏网达成协议!</P>
                </div>
              </div>
              <div class="two">
                <div class="two-title">二、图片使用权限</div>
                <div class="two-content">
                  <div class="goumai-input">
                    <p>1、视觉江苏网授予视觉版权作品使用方的图片使用权，仅是对特定图片的一次性使用权，使用范围为：</p>
                    <div class="info">
                      <span>A.报纸《 </span>
                      <el-input class="shuru" v-model="newsPaper"></el-input>
                      <span> 》</span>
                    </div>
                    <div class="info">
                      <span>B.网络《</span>
                      <el-input class="shuru" v-model="newWork"></el-input>
                      <span>》</span>
                    </div>
                    <div class="info">
                      <span>C.微信公众号《</span>
                      <el-input class="shuru" v-model="weChat"></el-input>
                      <span> 》</span>
                    </div>
                    <div class="info">
                      <span>D.客户端《</span>
                      <el-input class="shuru" v-model="client"></el-input>
                      <span>》</span>
                    </div>
                    <div class="info">
                      <span>E.杂志《</span>
                      <el-input class="shuru" v-model="zazhi"></el-input>
                      <span>》</span>
                    </div>
                    <div class="info">
                      <span>F.书籍《</span>
                      <el-input class="shuru" v-model="book"></el-input>
                      <span>》</span>
                    </div>
                    <div class="info">
                      <span>G.其他《</span>
                      <el-input class="shuru" v-model="other"></el-input>
                      <span>》</span>
                    </div>
                    <p>视觉版权作品使用方超出本协议授权范围使用图片，应事先向视觉江苏网说明有关特殊用途及要求，由视觉江苏网另行确定相关的使用费。</p>
                  </div>
                  <p>
                    2、视觉版权作品使用方对视觉江苏网授予使用的图片，不得再许可任何第三方对该图片的全部或局部进行再许可；不得向任何第三方对该图片的全部或局部进行买卖、转让、租借，不得将该图片的全部或局部或允许任何第三方将该图片的全部或局部作为任何一种服务标志或商标的全部或局部，不得在除本协议约定之外的任何网站或局域网或网络公告牌（BBS）上复制或展示该图片的全部或局部，不得将该图片的全部或局部放置在网上任何可供下载的位置或FTP格式下，不得将该图片的全部或局部用于诋毁或损害他人名誉、欺诈、侵犯、扭曲事实或其它一切非法用途。否则，由此造成的一切损失由视觉版权作品使用方承担。若视觉江苏网因此受到第三方的索赔，则视觉江苏网有权向视觉版权作品使用方追偿其所受到的所有损失和支出，包括但不限于律师费、诉讼费、赔偿金等。
                  </p>
                  <p>
                    3、未经视觉江苏网明确的事先书面许可，视觉版权作品使用方不得将图片用于本协议未列出的用途。视觉江苏网保证图片内容的完整性，视觉江苏网许可视觉版权作品使用方在不违背作者意愿的前提下基于排版或美观的需要对图片和所附的文字说明进行适当的编辑。视觉版权作品使用方如违反本项规定，则必须全额赔偿视觉江苏网因视觉版权作品使用方的未授权使用而不论以任何方式已产生及可能产生的任何索赔、损害、损失或其他任何支出，包括但不限于律师费、诉讼费、违约金等。
                  </p>
                  <p>4、视觉江苏网应保证图片的著作权没有瑕疵，包括肖像权（特别注明除外）。因图片著作权及肖像权产生商业纠纷，责任由视觉江苏网承担。</p>
                </div>
              </div>
              <div class="three">
                <div class="three-title">三、许可使用费</div>
                <div class="three-content">
                  <p>通过本网络下载图片并使用及时支付功能的使用方，价格以图片下方报价为准；通过协商办法购买本图片的，价格以纸质合同为准。</p>
                  <p>视觉版权作品使用方使用图片后，在出版物出版后30天内将刊物一式两份提供给视觉江苏网，新媒体请在图片下方标注链接。</p>
                </div>
                <div class="four">
                  <div class="four-title">四、使用要求</div>
                  <div class="four-content">
                    <p>
                      视觉版权作品使用方对于许可使用的每张图片均须附上一个可清晰辨认的图片来源署名；如用在封面，应在内文目录标注：“封面摄影
                      某某某”；如用在内文，可在图片说明最后标注“某某某摄
                      视觉江苏网供图”。如果视觉版权作品使用方未按上述约定附上此说明，则必须按该图片原使用费标准的五倍支付视觉江苏网违约金，同时承担摄影师提起的因侵犯著作权纠纷产生的一切损失。
                    </p>
                  </div>
                </div>
                <div class="five">
                  <div class="five-title">五、违约责任</div>
                  <div class="five-content">
                    <p>
                      1、如视觉版权作品使用方违反本协议许可他人使用图片的，则视觉版权作品使用方应支付给视觉江苏网擅自许可他人使用图片的双倍使用费作为违约金，同时承担由此给视觉江苏网及摄影师造成的损失。
                    </p>
                    <p>
                      2、因视觉版权作品使用方对图片或说明文字的任何不当使用而可能引起的视觉版权作品使用方或任何第三方的损失或损害，视觉江苏网不承担责任；如因视觉江苏网图片或说明文字的不当而可能引起的视觉版权作品使用方或任何第三方的损失或损害，视觉版权作品使用方不承担责任。
                    </p>
                    <p>3、任何一方均不承担因不可抗力（指不能预见、不能克服且不能避免的客观情况，包括但不限于暴乱、罢工等）所致延迟或无法履行本协议的责任，法律另有规定的除外。</p>
                  </div>
                </div>
                <div class="six">
                  <div class="six-title">六、协议争议的解决方式</div>
                  <div class="six-content">
                    <p>
                      本协议在履行过程中发生的争议，由双方当事人协商解决，协商不成的，任何一方有权向本协议签订地有管辖权的人民法院起诉。胜诉一方有权要求另一方赔偿合理的律师费及其它诉讼费用。
                    </p>
                  </div>
                </div>
                <div class="seven">
                  <div class="seven-title">七、保密义务</div>
                  <div class="seven-content">
                    <p>视觉版权作品使用方须保管好自己的用户名和密码，如因视觉版权作品使用方自己的原因造成用户名和密码泄漏，对因此造成的相关损失应由视觉版权作品使用方自己负责。</p>
                    <p>
                      视觉版权作品使用方如果发现自己的用户名和密码泄漏或者发现任何非法使用其用户名和密码的情况，有义务及时通知视觉江苏网(www.vijs. Net)，视觉江苏网(www.vijs.
                      Net)在接到通知后应当及时采取补救措施。
                    </p>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btnxieyi">
          <el-button type="danger" :disabled="!xieyiBtnShow" v-if="!xieyiBtnShow"> 请全部阅读以进行下一步 </el-button>
          <el-button @click="onGoTwo" type="danger" v-else>同意协议并继续下一步</el-button>
        </div>
      </div>
    </div>
    <cutPic class="cutCanvs" v-if="cutshadow" @editFinished="editFinished" :imgInfo="cutImg" @wheel.prevent="doNothing"> </cutPic>

    <cutCard class="cutCardCanvs" v-if="cutCardShow" @editFinishedCard="editFinishedCard" :idCardInfo="cutCard"> </cutCard>
  </div>
</template>

<script>
import RDUtils from '@/api/RDUtils'
import RD from '@/api/RD'
import SHA1 from 'js-sha1'
import cutPic from '@/views/cutHead.vue'
import cutCard from '@/views/cutIdCard.vue'
import Nav from '@/components/nav.vue'
import videoNav from '@/components/videoNav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom, videoNav, cutPic, cutCard },
  data: function () {
    return {
      smsCode: '',
      mobileInput: '',
      loginName: '',
      chnName: '',
      loginPassword: '',
      repw: '', // 重复密码
      extra: {
        address: '',
        bank: '',
        bankAcc: '',
        comment: '',
        company: '',
        email: '',
        exclusive: true,
        head: '',
        headImage: '',
        idBack: '',
        idFront: '',
        idNo: '',
        postCode: '',
        qq: '',
        sheji: '',
        sheying: '',
        telephone: '',
        topProducesList: [],
        topReward: '',
        wx: '',
        xinwen: '',
      },
      downloadUse: {
        newsPaper: '',
        newWork: '',
        weChat: '',
        client: '',
        zazhi: '',
        book: '',
        other: '',
      },
      telephone: {
        areaCode: '', // 固定电话区号
        maintelephone: '', // 固定电话的主号码
        fentelephone: '', // 分机号
      },
      // identifyingCode: '', // 输入的验证码
      // returnIdentifyingcode: '', // 手机返回来的验证码
      // input: '',
      // loginName: '',
      // realName: '',
      // IdCard: '', // 身份证号
      // IdCardFront: '', // 正面
      // IdCardBack: '', // 反面
      // password: '',
      // address: '',
      // company: '',
      // email: '',
      // postCode: '',
      // head: '',
      // repw: '',
      // reward: '', // 奖项
      // telephone: '',
      // sheji: '',
      // xinwen: '',
      // sheying: '',
      // bankAccont: '',
      // bank: '',
      // comment: '', // 个人简介
      // zhiwu: '',
      // weixin: '',
      // qq: '',
      // newsPaper: '',
      // newWork: '',
      // weChat: '',
      // client: '',
      // zazhi: '',
      // book: '',
      // other: '',

      pageStep: 1,
      userType: 1,
      idFrontImage: '',
      idCardUploadURL: '', // 上传身份证正面的地址
      idImgURLBack: '', // 上传身份证背面地址
      headImageUploadURL: '', // 上传头像的路径

      idImageURL: '', //本地身份证
      headImageURL: '', //本地头像

      sheyingList: ['国家级', '省级', '区市级'],
      xinwenList: ['国家级', '省级', '区市级'],
      shejiList: ['国家级', '省级', '区市级'],
      rewardList: ['国家级', '省级', '区市级'],
      daili: 3, // 注册的代理权限 代理权没有1,2何来3,4
      exclusive: false, //为啥这里你就用了boolean，而代理权没用
      navFlag: 1,
      requestSMS: true, // 验证码的显示
      requestSMSTimeout: 50,
      xieyiShow: false, // 协议的展示
      cutshadow: false, // 切图的显示
      cutCardShow: false, // 切身份证的显示
      cutImg: {}, // 切头像上传的数据
      cutCard: {}, // 切身份证
      xieyiBtnShow: false, // 协议的按钮是否可点
      warnings: {
        //post,email,qq,wx,telephone,bankAcc,idNo,loginName,password
      },
      //如果email有问题，就warnings.email = true，注意使用vue set，重置就直接warnings = {}
      // postShow: false, // 编码不合法时的提示
      // emailShow: false, // 邮箱不合法时的提示
      // qqShow: false, // qq不合法的提示
      // weixinShow: false, //
      // telephoneShow: false, //
      // bankAccShow: false, //
      // idcardShow: false, //
      // loginNameShow: false, //
      warnings: {
        email: false,
        qq: false,
        postCode: false,
        wx: false,
        bankAcc: false,
        maintelephone: false,
        loginName: false,
      },
      commmentLength: 0,
      imgCode: '', // 图形验证码
      codeImg: '', // 自动生成的验证码
    }
  },
  created() {
    var thiz = this
    thiz.headImage = RD.user().uploadPicUnregisted_URL()
    thiz.idFrontImage = RD.user().unregistID_URL()
    if (this.$route.query.id == 1) {
      thiz.navFlag = 1
    } else {
      thiz.navFlag = 2
    }
    if (thiz.$route.query.type == 'buy') {
      thiz.userType = 2
    }
    thiz.changeCode()
  },
  methods: {
    changeCode() {
      const thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    onRetrieveCode() {
      this.changeCode()
    },
    doNothing() {},
    //onGoPage1 onGoPage2 onGoPage3
    onGoNext() {
      this.xieyiShow = true
    },
    gotoScroll(e) {
      var scorllheight = e.srcElement.scrollHeight - 200
      if (e.srcElement.scrollTop + e.srcElement.offsetHeight >= scorllheight) {
        console.log('到底了')
        this.xieyiBtnShow = true
      }
    },
    onClose() {
      this.xieyiShow = false
      this.xieyiBtnShow = false
    },
    // 第一步验证
    onGoTwo() {
      this.xieyiShow = false
      this.pageStep = 2
    },
    // 是否是独家代理用户
    onChangeExclusive(value) {
      var thiz = this
      if (thiz.daili == 3) {
        thiz.exclusive = true
      } else {
        thiz.exclusive = false
      }
    },
    // 验证邮箱
    onSendEmail() {
      var thiz = this
      var regEmail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      if (thiz.extra.email != '' && !regEmail.test(thiz.extra.email)) {
        this.$message.error({
          message: '邮箱格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'email', true)
      } else {
        thiz.$set(thiz.warnings, 'email', false)
      }
    },
    // 验证QQ
    onSendQQ() {
      var thiz = this
      var regqq = /^[1-9][0-9]{4,10}$/
      if (thiz.extra.qq != '' && !regqq.test(thiz.extra.qq)) {
        this.$message.error({
          message: 'qq格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'qq', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'qq', false)
      }
    },
    // 验证微信
    onweixin() {
      var thiz = this
      var regweiixn = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/
      if (thiz.extra.wx != '' && !regweiixn.test(thiz.extra.wx)) {
        this.$message.error({
          message: '微信格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'wx', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'wx', false)
      }
    },
    // 区号
    onareaCode() {
      var thiz = this
      if (isNaN(parseInt(thiz.areaCode)) === true) {
        this.$message.error({
          message: '请输入数字区号',
          type: 'warning',
        })
        thiz.areaCode = ''
        return
      }
    },
    // 固定电话
    ontelephone() {
      var thiz = this
      var telmain = /^([0-9]{1})(\d{5,})$/
      if (thiz.telephone.maintelephone != '' && !telmain.test(thiz.telephone.maintelephone)) {
        thiz.$message.error({
          message: '请至少输入6位及以上数字号码',
          type: 'warning',
        })
        thiz.telephoneShow = true
        thiz.$set(thiz.warnings, 'maintelephone', true)
      } else {
        thiz.$set(thiz.warnings, 'maintelephone', false)
      }
    },
    // 验证银行账户
    onSendBank() {
      var thiz = this
      var regBank = /^([1-9]{1})(\d{11,18})$/
      if (thiz.bankAccont != '' && !regBank.test(thiz.bankAccont)) {
        this.$message.error({
          message: '银行账户格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'bankAcc', true)

        return
      } else {
        thiz.$set(thiz.warnings, 'bankAcc', false)
      }
    },
    // 身份证号验证
    onSendIdcard() {
      var thiz = this
      var regIdcard = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
      if (thiz.IdCard != '' && !regIdcard.test(thiz.IdCard)) {
        this.$message.error({
          message: '身份证格式不正确',
          type: 'warning',
        })

        return
      } else {
        thiz.$set(thiz.warnings, 'idNo', false)
      }
    },
    // 验证手机号
    onsetMobile() {
      var thiz = this
      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (thiz.mobileInput != '' && !mobile.test(thiz.mobileInput)) {
        thiz.$message('手机号不合法, 请重新填写')
      }
    },
    // 获取验证码
    onCaptcha() {
      var thiz = this
      if (!thiz.mobileInput) {
        this.$alert('手机号不能为空', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'warning',
          callback: (action) => {},
        })
        return
      }
      if (thiz.imgCode == '') {
        thiz.$message('图形验证码不能为空')
        return
      }
      thiz.requestSMSTimeout = 50
      RD.pure()
        .user()
        .requestSMSForRegister(thiz.imgCode, thiz.mobileInput)
        .then(() => {
          thiz.requestSMS = false
          setInterval(() => {
            thiz.requestSMSTimeout--
            if (thiz.requestSMSTimeout == 0) {
              thiz.requestSMS = true
            }
          }, 1000)
          thiz.$message('手机发送验证码成功，请注意查收')
        })
        .catch((error) => {
          thiz.$message(error.msg)
          this.changeCode()
          thiz.imgCode = ''
        })
    },
    // 下一步
    onNext() {
      var thiz = this
      if (thiz.mobileInput == '' || thiz.mobileInput == undefined) {
        thiz.$message('请先填写手机号')
        return
      }
      if (thiz.smsCode == '' || thiz.smsCode == undefined) {
        thiz.$message('请先填写手机验证码')
        return
      }
      RD.user()
        .confirmCodeForRegist(thiz.smsCode)
        .then(() => {
          thiz.pageStep = 3
        })
    },
    // 正则验证登录名
    onlogoName() {
      var thiz = this
      var name = /^[a-zA-Z0-9_-]{4,16}$/
      if (thiz.loginName != '' && !name.test(thiz.loginName)) {
        thiz.$message.error({
          message: '登录名不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'loginName', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'loginName', false)
      }
    },
    // 邮编
    onpostCode() {
      var thiz = this
      var code = /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/
      if (thiz.extra.postCode != '' && !code.test(thiz.extra.postCode)) {
        thiz.$message.error({
          message: '邮编不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'postCode', true)
      } else {
        thiz.$set(thiz.warnings, 'postCode', false)
      }
    },
    // 验证密码
    onpassword() {
      var thiz = this
      if (thiz.loginPassword != '' && !RDUtils.checkWeekPassword(thiz.loginPassword)) {
        this.$alert('新密码应至少8位，包含数字，大写和小写和特殊符号全部', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {},
        })
        return
      }
    },
    // 确认
    async onEnsure() {
      var thiz = this
      var regEmail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      if (thiz.extra.email != '' && !regEmail.test(thiz.extra.email)) {
        this.$message.error({
          message: '邮箱格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'email', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'email', false)
      }

      var code = /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/
      if (thiz.extra.postCode != '' && !code.test(thiz.extra.postCode)) {
        thiz.$message.error({
          message: '邮编不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'postCode', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'postCode', false)
      }

      var name = /^[a-zA-Z0-9_-]{4,16}$/
      if (thiz.loginName != '' && !name.test(thiz.loginName)) {
        thiz.$message.error({
          message: '登录名不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'loginName', true)

        return
      } else {
        thiz.$set(thiz.warnings, 'loginName', false)
      }

      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (thiz.mobileInput != '' && !mobile.test(thiz.mobileInput)) {
        thiz.$message('手机号不合法, 请重新填写')
        return
      }

      // var regIdcard = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
      // if (thiz.extra.idNo != '' && !regIdcard.test(thiz.extra.idNo)) {
      //   this.$message.error({
      //     message: '身份证格式不正确',
      //     type: 'warning',
      //   })
      //   thiz.idcardShow = true
      //   return
      // } else {
      //   thiz.idcardShow = false
      // }

      if (thiz.loginPassword != '' && !RDUtils.checkWeekPassword(thiz.loginPassword)) {
        this.$alert('新密码应至少8位，包含数字，大写和小写和特殊符号全部', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {},
        })
        return
      }

      var regweiixn = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/
      if (thiz.extra.wx != '' && !regweiixn.test(thiz.extra.wx)) {
        this.$message.error({
          message: '微信格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'wx', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'wx', false)
      }

      var regqq = /^[1-9][0-9]{4,10}$/
      if (thiz.extra.qq != '' && !regqq.test(thiz.extra.qq)) {
        this.$message.error({
          message: 'qq格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'qq', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'qq', false)
      }

      if (thiz.telephone.areaCode != '' && isNaN(parseInt(thiz.telephone.areaCode)) === true) {
        this.$message.error({
          message: '请输入数字区号',
          type: 'warning',
        })
        thiz.telephone.areaCode = ''
        return
      }

      var telmain = /^([0-9]{1})(\d{5,})$/
      if (thiz.telephone.maintelephone != '' && !telmain.test(thiz.telephone.maintelephone)) {
        thiz.$message.error({
          message: '请至少输入6位及以上数字号码',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'maintelephone', true)
      } else {
        thiz.$set(thiz.warnings, 'maintelephone', false)
      }

      var areatel = ''
      if (!thiz.telephone.areaCode) {
        areatel = ''
      } else {
        areatel = thiz.telephone.areaCode + '-'
      }

      var maintel = ''
      if (!thiz.telephone.maintelephone) {
        maintel = ''
      } else {
        maintel = thiz.telephone.maintelephone + '-'
      }

      thiz.extra.telephone = areatel + maintel + thiz.telephone.fentelephone

      if (!thiz.loginName) {
        thiz.$message('登录名不能为空')
        return
      }
      if (!thiz.chnName) {
        thiz.$message('真实姓名不能为空')
        return
      }

      if (!thiz.extra.idNo) {
        thiz.$message('身份证号不能为空,请检查身份证正面是否上传正确')
        return
      }

      // if (thiz.headImage == '' || thiz.headImage == undefined) {
      //   thiz.$message('请上传身份证正面')
      //   return
      // }

      if (!thiz.extra.address) {
        thiz.$message('地址不能为空')
        return
      }
      if (!thiz.extra.postCode) {
        thiz.$message('邮编不能为空')
        return
      }
      if (!thiz.extra.email) {
        thiz.$message('邮箱不能为空')
        return
      }

      if (thiz.extra.company == '' || thiz.extra.company == undefined) {
        thiz.$message('工作单位不能为空')
        return
      }

      if (thiz.userType == 1) {
        if (!thiz.extra.qq) {
          thiz.$message('qq不能为空')
          return
        }

        if (thiz.extra.wx == '' || thiz.extra.wx == undefined) {
          thiz.$message('微信不能为空')
          return
        }
        if (thiz.extra.bankAcc == '' || thiz.extra.bankAcc == undefined) {
          thiz.$message('银行账户不能为空')
          return
        }
        if (thiz.extra.bank == '' || thiz.extra.bank == undefined) {
          thiz.$message('开户行不能为空')
          return
        }
        if (thiz.extra.comment == '' || thiz.extra.comment == undefined) {
          thiz.$message('个人简介不能为空')
          return
        }
        if (thiz.extra.comment.length > 150 || thiz.extra.comment.length < 100) {
          thiz.$message('个人简介字数不符合要求')
          return
        }
      }

      if (thiz.extra.idFront == '' || thiz.extra.idFront == undefined) {
        thiz.$message('身份证正面不能为空')
        return
      }

      if (thiz.extra.idBack == '' || thiz.extra.idBack == undefined) {
        thiz.$message('身份证反面不能为空')
        return
      }

      if (thiz.extra.head == '' || thiz.extra.head == undefined) {
        thiz.$message('职务不能为空')
        return
      }

      var checkname = await RD.user().checkChnName(thiz.loginName)
      if (checkname) {
        thiz.pageStep = 3
        thiz.registed = false
      }

      var checkloginName = RD.user().checkLoginName(thiz.chnName)
      if (checkloginName) {
        thiz.pageStep = 3
        thiz.registed = false
      }

      console.log('topicsarr', thiz.extra.topProducesList)

      if (thiz.loginPassword != thiz.repw) {
        thiz.$message('两次输入的密码不一致，请重新输入')
        return
      }
      if (!thiz.loginPassword) {
        thiz.$message.error({ message: '密码不能为空', type: 'warning' })
        return
      }

      if (thiz.userType == 1) {
        if (thiz.extra.headImageg == '' || thiz.extra.headImage == undefined) {
          thiz.$message.error({ message: '头像不能为空', type: 'warning' })
          return
        }

        if (thiz.extra.topProducesList.length < 1) {
          thiz.$message.error({ message: '代表作至少上传一个', type: 'warning' })
          return
        }

        // var topPicArrs = []
        // thiz.extra.topProducesList.forEach((k) => {
        //   var topPics = {}
        //   topPics.uuid = k.uuid
        //   topPics.text = k.text
        //   topPicArrs.push(topPics)
        // })

        console.log('topicsarr', thiz.extra.topProducesList)
        // thiz.extra.topProducesList = topPicArrs

        console.log('register', thiz.loginName, SHA1(thiz.loginPassword), thiz.chnName, thiz.extra)
        var roleId = 21
        RD.user()
          .registerUploader(thiz.loginName, SHA1(thiz.loginPassword), thiz.chnName, thiz.extra, roleId)
          .then(() => {
            thiz.$message('注册上传者角色成功,等待审批后方可上传')
            this.$router.push('/picture.html')
          })
          .catch((error) => {
            thiz.$message(error)
          })
      } else {
        var roleId = 31
        RD.user()
          .registerDownloader(thiz.loginName, SHA1(thiz.loginPassword), thiz.chnName, thiz.extra, roleId)
          .then((info) => {
            console.log('kl', info)
            thiz.$message('注册下载者角色成功,等待审批后方可上传')
            thiz.$router.push('/picture.html')
          })
      }
    },
    // 上传头像成功后的处理
    onGetheadImage(res, file) {
      console.log('head', res)
      var thiz = this
      if (res.code == 0) {
        thiz.headImageUploadURL = URL.createObjectURL(file.raw)
        thiz.extra.headImage = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传身份证正面成功后的处理
    onSuccessFront(res, file) {
      console.log('zheng', res)
      if (res.code == 0) {
        this.extra.idNo = res.data.idNo
        this.idCardUploadURL = URL.createObjectURL(file.raw)
        this.extra.idFront = res.data.uuid
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传身份证反面成功后的处理
    onSuccessBack(res, file) {
      var thiz = this
      console.log('fan', res)
      if (res.code == 0) {
        thiz.idImgURLBack = URL.createObjectURL(file.raw)
        thiz.extra.idBack = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传代表作
    onSuccessRepresentative(res, file) {
      var thiz = this
      console.log('res', res)
      if (res.code == 0) {
        var top = {}
        top.imgURL = URL.createObjectURL(file.raw)
        top.uuid = res.data
        thiz.extra.topProducesList.push(top)
        console.log('topics', thiz.extra.topProducesList)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除代表作
    ondelTopPics(index) {
      console.log('ss', index)
      thiz.extra.topProducesList.splice(index, 1)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 切头像
    onCutHead() {
      if (this.headImageUploadURL) {
        this.cutshadow = true
        this.cutImg.src = RD.user().unregistedImageGet_URL(this.extra.headImage) + '?_=' + new Date().getTime()
        this.cutImg.width = window.innerWidth * 0.5
        this.cutImg.height = window.innerHeight * 0.5
      } else {
        this.$message('请先上传要剪切的头像')
      }
    },
    // 打开切头像的弹框
    editFinished(value, arrs) {
      console.log('cut', value)
      console.log('cutarrs', arrs)

      if (value == 2) {
        this.cutshadow = false
      }

      if (arrs != undefined) {
        RD.user()
          .uploadPicUnregistedCut(this.extra.headImage, arrs.x1, arrs.y1, arrs.x2, arrs.y2)
          .then(() => {
            this.$message('剪切图片成功')
            this.headImageUploadURL = RD.user().unregistedImageGet_URL(this.extra.headImage) + '?_=' + new Date().getTime()
          })
      }
    },
    // 剪切身份证
    onCutIdCard(type) {
      if (type == 'front') {
        if (this.idCardUploadURL) {
          this.cutCardShow = true
          this.cutCard.src = RD.user().unregistedImageGet_URL(this.extra.idFront) + '?_=' + new Date().getTime()
          this.cutCard.width = window.innerWidth * 0.5
          this.cutCard.height = window.innerHeight * 0.5
          this.cutCard.type = 'front'
        } else {
          this.$message('请先上传身份证正面')
        }
      } else {
        if (this.idImgURLBack) {
          this.cutCardShow = true
          this.cutCard.src = RD.user().unregistedImageGet_URL(this.extra.idBack) + '?_=' + new Date().getTime()
          this.cutCard.width = window.innerWidth * 0.5
          this.cutCard.height = window.innerHeight * 0.5
          this.cutCard.type = 'back'
        } else {
          this.$message('请先上传身份证反面')
        }
      }

      console.log('shs', this.cutCard)
    },
    // 切身份证的返回
    editFinishedCard(value, arrsCard, cutType) {
      console.log('cut', value)
      console.log('cutarrs', arrsCard)

      if (value == 2) {
        this.cutCardShow = false
      }

      if (arrsCard != undefined) {
        if (cutType == 1) {
          RD.user()
            .uploadPicUnregistedCut(this.extra.idFront, arrsCard.x1, arrsCard.y1, arrsCard.x2, arrsCard.y2)
            .then(() => {
              this.$message('剪切身份证成功')
              this.idCardUploadURL = RD.user().unregistedImageGet_URL(this.extra.idFront) + '?_=' + new Date().getTime()
            })
        } else {
          console.log('back')
          RD.user()
            .uploadPicUnregistedCut(this.extra.idBack, arrsCard.x1, arrsCard.y1, arrsCard.x2, arrsCard.y2)
            .then(() => {
              this.$message('剪切身份证成功')
              this.idImgURLBack = RD.user().unregistedImageGet_URL(this.extra.idBack) + '?_=' + new Date().getTime()
            })
        }
      }
    },
    // 检测简介的文字数量
    handleCommentChange() {
      var thiz = this
      thiz.commmentLength = this.extra.comment.length
    },
    // 失去焦点验证
    handlefinallychange() {
      var thiz = this
      if (thiz.commmentLength >= 150 || thiz.commmentLength <= 100) {
        this.$alert('最多不能超过150, 最少不能少于100', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
        return
      }
    },
  },
}
</script>

<style scoped lang="less">
.whole {
  height: auto;
}
.cutCanvs {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
.cutCardCanvs {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
.xieyi-show {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  background-color: rgba(0, 0, 0, 0.3);
  .close {
    width: 6rem;
    height: 6rem;
    line-height: 6rem;
    font-size: 5rem;
    border-radius: 3rem;
    position: absolute;
    background-color: #ccc;
    top: 5rem;
    right: 10rem;
    cursor: pointer;
  }
  .close:hover {
    color: red !important;
  }
  .zhuce {
    height: 60rem;
    overflow-y: auto;
    width: 80rem;
    background-color: #fff;
    position: absolute;
    padding: 3rem;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    .zhuce-title {
      font-size: 1.8rem;
    }
    .zhuce-con {
      height: 55rem;
      overflow: hidden;
      overflow-y: scroll;

      p {
        text-align: left;
        font-size: 1.3rem;
        line-height: 2.8rem;
        text-indent: 2em;
      }
      .xie {
        .shangchuan {
          width: 70rem;
          margin: 0 auto;
          height: auto;
          .title {
            font-size: 3.6rem;
            line-height: 9.5rem;
          }
          .xieyi {
            font-size: 3.2rem;
          }
          .one,
          .two,
          .three,
          .four,
          .five,
          .six,
          .seven,
          .eight {
            text-align: left;
            .one-title,
            .two-title,
            .three-title,
            .four-title,
            .five-title,
            .six-title,
            .seven-title,
            .eight-title {
              font-size: 2rem;
              font-weight: bold;
              text-indent: 3rem;
            }
            .one-content,
            .two-content,
            .three-content,
            .four-content,
            .five-content,
            .six-content,
            .seven-content,
            .eight-content {
              font-size: 1.8rem;
              line-height: 3rem;
              p {
                text-indent: 3rem;
              }
              div {
                height: 3rem;
              }
            }
          }
        }
        .buy {
          width: 70rem;
          margin: 0 auto;
          height: auto;
          .title {
            font-size: 3.6rem;
            line-height: 9.5rem;
          }
          .xieyi {
            font-size: 3.2rem;
          }
          .one,
          .two,
          .three,
          .four,
          .five,
          .six,
          .seven {
            text-align: left;
            .one-title,
            .two-title,
            .three-title,
            .four-title,
            .five-title,
            .six-title,
            .seven-title,
            .eight-title {
              font-size: 2rem;
              font-weight: bold;
              text-indent: 3rem;
            }
            .one-content,
            .two-content,
            .three-content,
            .four-content,
            .five-content,
            .six-content,
            .seven-content {
              font-size: 1.8rem;
              line-height: 3rem;
              p {
                text-indent: 3rem;
              }
              .goumai-input {
                height: auto;
                .info {
                  margin-left: 3.4rem;
                  color: red;
                  ::v-deep .el-input__inner {
                    border: none;
                  }
                  .shuru {
                    width: 20rem;
                  }
                }
              }

              div {
                height: 3rem;
              }
            }
          }
          .eight {
            margin: 0.5rem 0rem;
            text-align: center;
          }
        }
      }
    }

    .btnxieyi {
      width: 80rem;
      height: 4rem;
      line-height: 4rem;
    }
  }
}
.registerAll {
  min-height: calc(100vh - 32.7rem);
  width: 120rem;
  margin: 0 auto;
  .bindRole {
    height: auto;
    min-height: 58.5rem;
    margin: 0 auto;
    position: relative;
    font-size: 1.5rem;
    .step-role {
      position: absolute;
      top: 2rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border: 0.1rem solid rgb(105, 241, 189);
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
    }
    .step-rlink {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 100%;
      width: 0.1rem;
    }
    .role-con {
      margin: 0 auto;
      width: 60rem;
      height: auto;
      .role-text {
        height: 6rem;
        padding-top: 2rem;
        font-size: 2rem;
      }
      .role {
        padding-top: 2rem;
        height: 8.8rem;
        text-align: left;
        .role-type {
          height: 4rem;
          .el-radio-group {
            text-align: left;
            .el-radio {
              margin-right: 20rem;
              font-size: 1.3rem;
            }
          }
        }
        .dailipower {
          margin-top: 2rem;
          margin-left: 0rem;
        }
      }
      .link {
        display: inline-block;
        position: relative;
        height: 8rem;
        width: 100%;
        .el-button {
          position: absolute;
          right: 5rem;
          top: 40%;
        }
      }
    }
  }
  .bindMobile {
    height: auto;
    margin: 0 auto;
    position: relative;
    .step-one {
      position: absolute;
      top: 2rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border: 0.1rem solid rgb(105, 241, 189);
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
    }
    .step-link {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 100%;
      min-height: 58.3rem;
      width: 0.1rem;
    }
    .mobile-con {
      margin: 0 auto;
      width: 60rem;
      position: relative;
      font-size: 1.4rem;
      .mobile {
        text-align: left;
        padding-top: 50px;
        span {
          display: inline-block;
          width: 84px;
          font-size: 1.4rem;
        }
        .el-input {
          display: inline-block;
          width: 30rem;
        }
      }
      .yanzhengCode {
        height: 5rem;
        line-height: 5rem;
        text-align: left;
        display: flex;
        span {
          display: inline-block;
        }
        .el-input {
          width: 30rem;
        }
        .el-button {
          padding: 5px 12px;
          height: 4rem;
          margin-top: 5px;
        }
        img {
          margin-left: 15px;
          height: 38px;
          margin-top: 7px;
        }
      }
      .mobileback {
        text-align: left;
        .el-input {
          display: inline-block;
          width: 30rem;
        }
        .el-button {
          margin-left: 1.5rem;
        }
      }
      .step {
        margin-right: 2rem;
        text-align: right;
        .el-button {
          margin-top: 2.5rem;
          margin-right: 2rem;
        }
      }
    }
  }
  .bindName {
    height: auto;
    border-bottom: 0.1rem solid #ccc;
    position: relative;
    top: 0rem;
    left: 0rem;
    .step-two {
      position: absolute;
      top: 0rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
      border: 0.1rem solid rgb(105, 241, 189);
    }
    .step-tlink {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 95.5%;
      width: 0.1rem;
    }
    .name-con {
      margin-left: 28rem;
      width: 77.5rem;
      font-size: 1.5rem;
      .tip {
        text-align: left;
        height: 4rem;
        line-height: 4rem;
        color: red;
      }
      .btnsure {
        height: 6rem;
        margin-top: 3rem;
        text-align: right;
        margin-right: 20rem;
        margin-bottom: 0.5rem;
      }
      .cname {
        text-align: left;
        .el-input {
          display: inline-block;
          width: 22rem;
        }
      }
      .tel {
        text-align: left;
        margin-top: 3vh;
      }
      .longname,
      .address,
      .email,
      .Idcard,
      .mobile,
      .fax,
      .company,
      .postCode,
      .realName,
      .qq,
      .weixin,
      .zhiwu,
      .bankaccont,
      .head {
        text-align: left;
        margin-top: 1.2rem;
        box-sizing: border-box;
        height: 4rem;
        line-height: 4rem;
        span {
          display: inline-block;
          width: 23.1rem;
        }
        .el-input {
          display: inline-block;
          width: 29rem;
          margin-right: 1rem;
        }
        .redBorder {
          border: 0.2rem solid red;
        }
      }
      .Idcard {
        .red {
          color: red;
        }
      }
      .comment {
        text-align: left;
        margin-top: 1.2rem;
        box-sizing: border-box;
        height: 23rem;
        line-height: 4rem;
        span {
          display: inline-block;
          width: 23.1rem;
        }
        .el-textarea {
          display: inline-block;
          width: 60rem;
          margin-right: 1rem;
        }
        .currenrsize {
          height: 5rem;
          font-style: normal;
        }
      }
      .representative {
        height: auto;
        margin-top: 2rem;
        .representative-one {
          height: auto;
          display: flex;
          .representative-left {
            width: 23.1rem;
            text-align: left;
          }
          .representative-right {
            height: auto;
            width: 50rem;
            display: flex;
            flex-shrink: 0;
            .uploadTop {
              width: 11.5rem;
              height: 3rem;
              line-height: 3rem;
              border: 0.1rem solid #ccc;
              margin-right: 2rem;
            }
            .topPics {
              height: auto;
              width: 30.4rem;
              .top-con {
                margin-bottom: 2rem;
                position: relative;
                .top-del {
                  position: absolute;
                  right: -4rem;
                  top: 0rem;
                  width: 4rem;
                  height: 3rem;
                  font-size: 2rem;
                  padding-top: 0.8rem;
                  border-radius: 50%;
                  background-color: rgba(0, 0, 0, 0.3);
                }
                .top-del:hover {
                  color: red;
                }
                .top-img {
                  width: 100%;
                  height: 15rem;
                  background: no-repeat 50% / cover;
                }
                .introl {
                  height: 12rem;
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .telephone {
        text-align: left;
        margin-top: 3.3rem;
        box-sizing: border-box;
        height: 5rem;
        line-height: 5rem;
        display: flex;
        .tel-name {
          width: 23.1rem;
        }
        .area {
          display: flex;
          .area-name {
            width: 4.7rem;
          }
          .el-input {
            width: 10rem;
            margin-right: 1rem;
          }
        }
        .tel-main {
          display: flex;
          .main-name {
            width: 6.5rem;
          }
          .el-input {
            width: 15.5rem;
            margin-right: 1rem;
          }
          .redBorder {
            border: 0.2rem solid red;
          }
        }
        .tel-fen {
          display: flex;
          .fen-name {
            width: 6.5rem;
          }
          .el-input {
            width: 9rem;
          }
        }
      }
      .bank {
        text-align: left;
        margin-top: 1.2rem;
        span {
          display: inline-block;
          width: 31rem;
        }
        .el-input {
          display: inline-block;
          width: 29rem;
        }
      }
      .el-button {
        margin-top: 0.5rem;
        margin-left: 35rem;
      }
      .Idcardfront {
        text-align: left;
        margin-top: 2rem;
        height: 18rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        span {
          width: 23.1rem;
        }
        .border {
          height: 17.8rem;
          width: 33.3rem;
          border: 0.1rem solid #ccc;
          .avatar-uploader-icon {
            font-size: 2.8rem;
            color: #8c939d;
            width: 100%;
            height: 100%;
            line-height: 20rem;
            text-align: center;
          }
          ::v-deep.el-upload {
            width: 33.3rem;
            height: 17.8rem;
            .avatar {
              width: 100%;
              height: 100%;
              background: no-repeat 50% / contain;
            }
          }
        }
        .cutFront {
          width: 13rem;
          padding-left: 0.5rem;
          height: 4rem;
          line-height: 4rem;
          border: 0.1rem solid #ccc;
          border-radius: 0.5rem;
          margin-left: 1rem;
          margin-top: 7.5rem;
          cursor: pointer;
        }
      }
      .Idcardback {
        text-align: left;
        margin-top: 2rem;
        height: 18rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        span {
          width: 23.1rem;
        }
        .border {
          height: 17.8rem;
          width: 33.3rem;
          border: 0.1rem solid #ccc;
          .avatar-uploader-icon {
            font-size: 2.8rem;
            color: #8c939d;
            width: 100%;
            height: 100%;
            line-height: 20rem;
            text-align: center;
          }
          ::v-deep.el-upload {
            width: 33.3rem;
            height: 17.8rem;
            .avatar {
              width: 100%;
              height: 100%;
              background: no-repeat 50% / contain;
            }
          }
        }
        .cutBack {
          width: 13rem;
          padding-left: 0.5rem;
          height: 4rem;
          line-height: 4rem;
          border: 0.1rem solid #ccc;
          border-radius: 0.5rem;
          margin-left: 1rem;
          margin-top: 7.5rem;
          cursor: pointer;
        }
      }
      .photo {
        margin-top: 2rem;
        height: 20rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        .text {
          width: 20.8rem;
          text-align: left;
        }
        .border {
          width: 20rem;
          height: 20rem;
          border-radius: 10rem;
          border: 0.1rem solid #ccc;
          overflow: hidden;
          .avatar-uploader-icon {
            font-size: 2.8rem;
            color: #8c939d;
            width: 100%;
            height: 100%;
            line-height: 20rem;
            text-align: center;
          }
          ::v-deep.el-upload {
            width: 100%;
            height: 100%;
            .showimg {
              width: 20rem;
              height: 20rem;
              background: no-repeat 50% / cover;
            }
          }
        }
        .cutPic {
          margin-left: 1rem;
          border-radius: 0.5rem;
          margin-top: 7.5rem;
          width: 10rem;
          height: 4rem;
          line-height: 4rem;
          border: 0.1rem solid #ccc;
          cursor: pointer;
        }
      }
      .pw,
      .resetpw {
        text-align: left;
        margin-top: 3.3rem;
        height: 5rem;
        line-height: 5remx;
        display: flex;
        span {
          width: 24.1rem;
        }
        .el-input {
          display: inline-block;
          width: 25rem;
          margin-right: 0.8rem;
          margin-top: -0.5rem;
          ::v-deep .el-input__suffix {
            height: 80%;
          }
        }
        .redBorder {
          border: 0.2rem solid red;
        }
      }
      .daibiao1,
      .daibiao2,
      .daibiao3 {
        margin-top: 2rem;
        height: 18rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        .text {
          width: 16rem;
          text-align: left;
        }
        .daibiaoPic {
          height: 10rem;
          margin-left: 1rem;
          width: 20rem;
          border: 0.1rem solid #ccc;
        }
      }
      .vip,
      .xinwen,
      .sheji,
      .reward,
      .daibiao1Text,
      .daibiao2Text,
      .daibiao3Text {
        text-align: left;
        margin-top: 1.2rem;
        span {
          display: inline-block;
          width: 27rem;
        }
        .el-input {
          width: 30rem;
        }
      }
    }
  }
  .bindPassWord {
    height: auto;
    border-bottom: 0.1rem solid #ccc;
    position: relative;
    top: 0rem;
    left: 0rem;
    .step-three {
      position: absolute;
      top: 0rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
      border: 0.1rem solid rgb(105, 241, 189);
    }
    .step-thlink {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 75%;
      width: 0.1rem;
    }
    .password-con {
      margin: 0 auto;
      width: 36vw;
      .tel,
      .name {
        text-align: left;
        margin-top: 3vh;
        font-size: 1.3rem;
        span {
          margin-left: 1.5rem;
        }
      }

      .el-button {
        margin-top: -0.5rem;
        margin-left: 35rem;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 0.1rem dashed #d9d9d9;
  border-radius: 0.6rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
</style>
