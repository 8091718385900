import { render, staticRenderFns } from "./videoNav.vue?vue&type=template&id=6030c782&scoped=true&"
import script from "./videoNav.vue?vue&type=script&lang=js&"
export * from "./videoNav.vue?vue&type=script&lang=js&"
import style0 from "./videoNav.vue?vue&type=style&index=0&id=6030c782&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6030c782",
  null
  
)

export default component.exports