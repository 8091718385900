<template>
  <div class="videoNav">
    <div class="nav-con">
      <div class="nav-left">
        <div class="title-log">
          <div class="title-img" @click="ongoIndex">
            <div class="bg-img"></div>
          </div>
          <div class="title-white" @click="onGoVideoIndex">视频资源库</div>
        </div>
      </div>
      <div class="nav-right">
        <div class="other">
          <div class="select">
            <el-select v-model="searchkey" filterable placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="search" v-if="searchkey == 1">
            <el-input class="serarch-input" v-model="search" placeholder="所搜即所得" @keyup.enter.native="onSearch"></el-input>
            <div class="icon el-icon-search" @click="onSearch"></div>
          </div>
          <div class="logo" v-if="!loginedStatus">
            <el-input v-model="userName" placeholder="用户名"></el-input>
            <el-input v-model="passWord" placeholder="密码" show-password></el-input>
            <div class="backpass">找回密码>></div>
            <el-input class="yanzheng" v-model="code" placeholder="验证码" @keyup.enter.native="onLogoin"></el-input>
            <img :src="codeImg" alt="" class="yan-img" v-if="!$rtm.store.logined" @click="onLogo" />
            <div class="logon">
              <el-button class="logoin" @click="onLogoin">登录</el-button>
              <el-button class="register" @click="onRegister(2)">注册</el-button>
            </div>
          </div>
          <div class="logoed" v-else>
            <div class="mine" v-if="loginedStatus == 0" @click="onMyInfo"></div>
            <div class="uploadVideo" @click="onUploadVideo" v-if="noUpload"></div>
            <div class="myVideo" @click="onMyVideo" v-if="noUpload"></div>
            <div class="logoOut" @click="onLogoOut"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-content">
      <div class="nav-link">
        <div class="nav-list" v-for="(k, nIndex) in navData" :key="nIndex + 'na_'">
          <!-- <div class="nav-title">{{ k.name }}</div> -->
          <a v-if="k.dis" @click="onUserMore">{{ k.name }}</a>
          <a v-else :href="k.url" target="_blank">{{ k.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'
import sha1 from 'js-sha1'

export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: '关键词',
        },
      ],
      searchkey: 1,
      search: '',
      userName: '', // 用户名
      passWord: '', // 密码
      codeImg: '', // 验证码
      code: '', // 验证码
      noUpload: false, // 是否显示上传视频的按钮
      navData: [
        { name: '热点', url: '/videoFixtopic.html?id=41' },
        { name: '滚动', url: '/videoFixtopic.html?id=42' },
        { name: '推荐', url: '/videoFixtopic.html?id=43' },
        { name: '科教', url: '/videoFixtopic.html?id=44' },
        { name: '经济', url: '/videoFixtopic.html?id=45' },
        { name: '社会', url: '/videoFixtopic.html?id=56' },
        { name: '民生', url: '/videoFixtopic.html?id=57' },
        { name: '环境', url: '/videoFixtopic.html?id=58' },
        { name: '下载', url: '/videoFixtopic.html?id=41', dis: true },
        { name: '上传', url: '/uploadVideo.html' },
      ],
      loginedStatus: -1, // 登录的状态
    }
  },
  created() {
    this.getSync()
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.getSync()
    }
  },
  methods: {
    getSync() {
      var thiz = this
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          thiz.$rtm.store.userInfo = data
          thiz.loginedStatus = 0
          if (data.approveStatusName == '未通过') {
            thiz.noUpload = false
          } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
            thiz.noUpload = true
          }
          thiz.reload()
        })
        .catch((error) => {
          thiz.loginedStatus = error.code
          this.checkLogin(error)
        })
    },
    reload() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    //  登录
    onLogoin() {
      var thiz = this
      console.log('code', thiz.code)
      if (isNaN(thiz.code)) {
        RD.pure()
          .user()
          .login(thiz.code, thiz.userName, sha1(thiz.passWord))
          .then((data) => {
            thiz.loginedStatus = 0
            thiz.$rtm.store.userInfo = data
            if (data.approveStatusName == '未通过') {
              thiz.noUpload = false
            } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
              thiz.noUpload = true
            }
            thiz.$rtm.store.logined = true
            // thiz.loginFlag = 2;
            thiz.code = ''
            thiz.loading = false
            // thiz.load();
            thiz.$router.replace('/video.html').catch(() => {
              thiz.reload()
            })
          })
          .catch((error) => {
            // thiz.$message(error.msg)
            // thiz.code = ''
            // thiz.loading = false
            // thiz.onLogo()
            thiz.loginedStatus = error.code
            this.checkLogin(error)
          })
      } else {
        thiz.$message('请输入验证码再登录')
      }
    },
    checkLogin(error) {
      const thiz = this
      console.log('loginCode', error)
      // console.log('jkl', this.navCode)
      if (error.code == this.navCode && this.navCode != 0) {
        return
      }
      if (error.code == 20) {
        this.$alert(error.msg, '提示', {
          confirmButtonText: '前往验证',
          showClose: false,
          type: 'warning',
          callback: (action) => {
            thiz.$router.push({
              path: '/checkLogin.html',
            })
          },
        })
      } else if (error.code == 21) {
        this.$alert(error.msg, '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {
            thiz.resendDualAuthSMS()
          },
        })
      } else if (error.code == 30) {
        this.$confirm(error.msg, '提示', {
          confirmButtonText: '修改密码',
          cancelButtonText: '退出登录',
          showClose: false,
          type: 'warning',
        })
          .then(() => {
            thiz.$router.push({
              path: '/resetPassword.html',
            })
          })
          .catch(() => {
            this.onOutLogin()
          })
      } else if (error.code == 2) {
        this.$alert(error.msg, '提示', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
        thiz.code = ''
      } else {
        thiz.code = ''
        thiz.loading = false
        thiz.onLogo()
      }
    },
    // 5次失效后的验证
    resendDualAuthSMS() {
      RD.pure()
        .user()
        .resendDualAuthSMS()
        .then(() => {})
        .catch((error) => {
          if (error.code == 20) {
            this.$alert(error.msg, '提示', {
              confirmButtonText: '确定',
              callback: (action) => {},
            })
          } else {
            this.$alert(error.msg, '提示', {
              confirmButtonText: '确定',
              callback: (action) => {
                location.reload()
              },
            })
          }
        })
    },
    // 图形验证码刷新
    onLogo() {
      var thiz = this
      thiz.logoShow = true
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    // 回欢迎页
    ongoIndex() {
      this.$router.push('/picture.html')
    },
    // 退出登录
    onLogoOut() {
      var thiz = this
      RD.user()
        .logout()
        .then(() => {
          thiz.$rtm.store.logined = false
          // thiz.$router.replace('/video.html')
          location.reload()
          thiz.onLogo()
        })
    },
    // 用户信息
    onMyInfo() {
      let routerUrl = this.$router.resolve({
        path: '/myInfo.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    // 注册页面
    onRegister(type) {
      this.$router.push({
        path: '/register.html',
        query: { id: type },
      })
    },
    onUploadVideo() {
      let routerUrl = this.$router.resolve({
        path: '/uploadVideo.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    onMyVideo() {
      let routerUrl = this.$router.resolve({
        path: '/myPost.html',
        query: { flag: 2 },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 返回视频首页
    onGoVideoIndex() {
      this.$router.push('/video.html')
    },
    // 进入搜索页面
    onSearch() {
      var thiz = this
      if (thiz.loginedStatus) {
        let routerUrl = this.$router.resolve({
          path: '/videoSearch.html',
          query: { keyWord: thiz.search },
        })
        window.open(routerUrl.href, '_blank')
      } else {
        thiz.$message('请登录后使用此方法')
      }
    },
    onUserMore() {
      this.$alert('栏目建设中', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      })
        .then(() => {})
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.videoNav {
  height: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
  .nav-con {
    width: 120rem;
    margin: 0 auto;
    display: flex;
    height: 10rem;
    .nav-left {
      width: 40%;
      height: 100%;
      position: relative;
      .title-log {
        width: 38.2rem;
        height: 100%;
        position: absolute;
        left: 0rem;
        top: 0rem;
        .title-img {
          position: absolute;
          left: 0rem;
          top: -3.5rem;
          width: 67%;
          height: 13rem;
          border-right: 0.1rem solid #ccc;
          cursor: pointer;
          .bg-img {
            position: absolute;
            left: -2rem;
            top: 2.9rem;
            width: 26rem;
            height: 10rem;
            background: no-repeat url(../assets/logo.png);
            background-size: cover;
            transform: scale(1.3);
          }
        }
        .title-white {
          width: 18.5rem;
          height: 5.1rem;
          line-height: 5.1rem;
          position: absolute;
          left: 67%;
          top: 2.3rem;
          background-color: #005573;
          color: #fff;
          font-size: 2rem;
          cursor: pointer;
        }
      }
    }
    .nav-right {
      width: 65%;
      margin-left: 2%;
      .nav-title {
        height: 50%;
        display: flex;
        background-color: #00b2ec;
        .nav-list {
          width: 10rem;
          height: 100%;
          line-height: 5rem;
          color: #fff;
          font-size: 1.5rem;
        }
      }
      .other {
        display: flex;
        margin-top: 2.8rem;
        position: relative;
        .select {
          width: 9rem;
        }
        .search {
          width: 17.8rem;
          display: flex;
          position: relative;
          .el-input {
            width: 17rem;
            margin: 0rem 0.5rem;
          }
          .icon {
            position: absolute;
            right: 0.5rem;
            top: 0rem;
            width: 5rem;
            font-size: 2.5rem;
            padding-top: 0.5rem;
            border-radius: 0.5rem;
            height: 3rem;
            cursor: pointer;
          }
        }
        .logo {
          display: flex;
          position: absolute;
          right: 0rem;
          top: 0rem;
          height: 4rem;
          .el-input {
            width: 9.5rem;
            padding: 0rem;
            margin: 0rem 0.1rem;
          }
          .yan {
            width: 5rem;
            border: 0.1rem solid #ccc;
            line-height: 4.5rem;
            height: 3.8rem;
            margin-right: 0.3rem;
          }
          .yan-img {
            width: 10rem;
            height: 4rem;
            background: no-repeat 50% / cover;
          }
          .yanzheng {
            width: 8.5rem;
          }
          .backpass {
            position: absolute;
            right: 25rem;
            top: 5rem;
            cursor: pointer;
          }
          .el-button {
            width: 6rem;
            height: 4rem;
            margin: 0rem;
            padding: 0rem;
          }
          .logon {
            width: 6rem;
            margin-left: 0.5rem;
          }
        }
        .logoed {
          display: flex;
          position: absolute;
          top: 0rem;
          right: 0rem;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.2rem;
          .mine {
            width: 10rem;
            margin-top: 0.5rem;
            cursor: pointer;
            background: no-repeat url(../assets/myinfobefore.png);
            background-size: contain;
          }
          .mine:hover {
            background: no-repeat url(../assets/myinfo.png);
            background-size: contain;
          }
          .uploadVideo {
            width: 10rem;
            margin-top: 0.5rem;
            cursor: pointer;
            background: no-repeat url(../assets/upvideo.png);
            background-size: contain;
          }
          .uploadVideo:hover {
            background: no-repeat url(../assets/upvideoAfter.png);
            background-size: contain;
          }
          .myVideo {
            width: 10rem;
            margin-top: 0.5rem;
            cursor: pointer;
            background: no-repeat url(../assets/myvideo.png);
            background-size: contain;
          }
          .myVideo:hover {
            background: no-repeat url(../assets/myvideoAfter.png);
            background-size: contain;
          }
          .logoOut {
            width: 10rem;
            margin-top: 0.5rem;
            cursor: pointer;
            background: no-repeat url(../assets/loginoutbefore.png);
            background-size: contain;
          }
          .logoOut:hover {
            background: no-repeat url(../assets/loginout.png);
            background-size: contain;
          }
        }
      }
    }
  }
  .nav-content {
    width: 100%;
    height: 30%;
    display: flex;
    margin: 2rem 0rem;
    background-color: #898989;
    position: relative;
    color: #fff;
    .nav-log {
      width: 3rem;
      height: 5rem;
      font-size: 5rem;
      display: flex;
      justify-content: flex-start;
      div {
        width: 3rem;
        height: 5rem;
        font-size: 3rem;
        line-height: 5rem;
      }
    }
    .nav-link {
      width: 120rem;
      height: 5rem;
      margin: 0 auto;
      display: flex;
      .nav-list {
        flex: 1;
        cursor: pointer;
        .nav-title {
          font-size: 1.4rem;
          margin-right: 2rem;
          height: 5rem;
          line-height: 5rem;
          width: 100%;
        }
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          line-height: 5rem;
          color: #fff;
          text-decoration: none;
          padding: 0rem 1rem;
        }
      }
      .nav-list.active {
        border-bottom: 0.3rem solid red;
      }
    }
    .nav-number {
      width: 20rem;
      height: 5rem;
      line-height: 5rem;
      font-size: 1.3rem;
      font-weight: bold;
      display: flex;
      margin-top: 1rem;
      .fapian {
        width: 7rem;
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        color: red;
        padding-right: 0.5rem;
        cursor: pointer;
      }
      .number {
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        text-align: left;
      }
    }
  }
}
</style>
